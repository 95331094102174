/* =============================
   Base Container Styles
   ============================= */
.markdown-content {
    padding-bottom: 12px !important;
    line-height: 1.4 !important; /* Ensures good readability */
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    white-space: normal !important;
    max-height: none !important; /* Prevents unwanted truncation */
    margin-top: -0.2em !important; /* Reduced top margin for the first element */
    margin-bottom: 0.2em !important; /* Reduced bottom margin for the last element */
}

/* =============================
   Headings Styling
   ============================= */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3 {
    margin-top: 0.3em !important;
    margin-bottom: 0.3em !important;
    line-height: 1.3 !important;
}

/* =============================
   Paragraph Styling
   ============================= */
.markdown-content p {
    margin: 0em 0 !important;
    line-height: 1.4 !important;
    overflow: visible !important; /* Ensures full text is shown */
    margin-bottom: 0.8em !important; /* Ensures space after paragraphs */
}

.markdown-content p:last-child {
    margin-bottom: -1.2em !important; /* Prevents text from getting cut off */
}

/* Ensure bold text inside paragraphs remains inline */
.markdown-content p > strong {
    display: inline !important;
    margin-right: 0.2em !important; /* Ensures space after bold text but keeps inline */
}

/* Ensure bold text inside list items remains inline */
.markdown-content li > strong {
    display: inline !important;
    margin-right: 0.2em !important; /* Adds spacing after bold text in lists */
}

/* =============================
   List Styling (Ordered & Unordered)
   ============================= */
.markdown-content ul,
.markdown-content ol {
    margin: 0 !important;
    padding-left: 2.5em !important;
}

.markdown-content li {
    margin: 1em 0 0.5em 0 !important; /* Adds space above and after the first bullet point */
    padding-left: 0 !important;
    line-height: 1.3 !important;
    display: list-item !important;
}

.markdown-content li:first-child {
    margin-top: 0.8em !important; /* Ensures extra space above the first bullet point */
}

.markdown-content li:last-child {
    margin-top: 0.8em !important; /* Ensures extra space above the first bullet point */
    margin-bottom: 1em !important; /* Ensures extra space below the last bullet point */
}

.markdown-content li > p {
    margin: 0 !important;
    display: inline !important;
    line-height: 1.3 !important;
}

/* Proper indentation for nested lists */
.markdown-content ul ul,
.markdown-content ul ol,
.markdown-content ol ul,
.markdown-content ol ol {
    margin: 0 0 0 1em !important;
}

/* Ensure list items are properly spaced */
.markdown-content li + li {
    margin-top: 0.2em !important;
}

/* =============================
   Inline Code & Code Blocks
   ============================= */
.markdown-content code {
    background-color: #282c34 !important;
    color: #e06c75 !important;
    padding: 2px 4px !important;
    border-radius: 3px !important;
    font-family: 'Courier New', monospace !important;
    font-size: 0.95em !important;
}

.markdown-content pre {
    background-color: #1e1e1e !important;
    color: #ffffff !important;
    padding: 10px !important;
    border-radius: 5px !important;
    overflow-x: auto !important;
}

/* =============================
   Document Icons
   ============================= */
.file-icon {
    vertical-align: -0.125em !important;
    margin: 0.1em !important;
}

.markdown-content span.file-wrapper {
    display: inline-flex !important;
    align-items: center !important;
    margin: 0 !important; /* Remove all margins */
    margin-right: -0.1em !important; /* Pull subsequent content slightly closer */
}
